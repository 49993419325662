
/* eslint-disable */
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.73.8 (e9fa466fc3c6e8f31a7c83d97c78518a)",
    buildSeed: 1665757807348,
    "wdosbox.wasm": {
        "size": 1462485,
        "gzSize": 499437
    },
    "wdosbox.js": {
        "size": 124967,
        "gzSize": 32625
    },
    "wlibzip.wasm": {
        "size": 110726,
        "gzSize": 51367
    },
    "wlibzip.js": {
        "size": 77090,
        "gzSize": 19985
    }
,
};
